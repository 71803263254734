
































































































































































































































































@import '~variables'

.ticket-public
  display flex
  margin auto
  max-width 1000px
  background-color white
  .grid-tile__label
    margin-bottom $space-1
  .ticket-main
    display flex
    flex 2
    flex-direction column
    border-right $grid-border
    .ticket-title-container
      padding $space-2
      .ticket-title
        font-size $fs-h1
    .status-container
      padding $space-2
    .ticket-infos
      flex-wrap wrap
    .message-container
      flex-direction column
      padding $space-2
      .form-textarea
        width 100%
        outline none
        border none
        font-size $fs-h2
        resize none
    .comments-container
      padding $space-2
      .comment-list
        width 100%
  .side-container
    display flex
    flex 1
    flex-direction column
    height 100%
    .map-container
      padding $space-2
      width 100%
      height 355px
    .form-value
      padding $space-2
      padding-top 0

.documents-container
  flex-direction column
  padding $space-2
  text-align center
  .grid-tile__label
    text-align left
  .files-list
    display flex
    flex-direction column
    padding 8px
    .file-item
      position relative
      display flex
      flex-direction column
      justify-content center
      align-items center
      margin-bottom 8px
      padding 16px
      border 1px solid lighten($grey, 70%)
      border-radius 5px
      box-shadow $shadow-elevation-1
      color darken($grey, 30%)
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size 0.9rem
      transition box-shadow 0.3s ease
      &--download
        cursor pointer
        &:hover
          box-shadow $shadow-elevation-2
  .input-files
    position absolute
    z-index -1
    overflow hidden
    width 0.1px
    height 0.1px
    opacity 0
  .input-files + label
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding $space-2
    border 1px solid black
    border-radius $border-radius-smooth
    box-shadow $shadow-elevation-1
    text-transform uppercase
    letter-spacing 0.5px
    font-weight 700
    font-size 0.9rem
    cursor pointer
    transition all 100ms ease
    .picto
      margin-bottom 8px
    &:hover
      box-shadow $shadow-elevation-2
    &:focus
      outline 1px dotted #000
      outline -webkit-focus-ring-color auto 5px
    .inputfile + label *
      pointer-events none

.floor-container
  width 100%
  height 100%
  text-align center
  transition:
    top 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    left 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    z-index 0.2s cubic-bezier(0.02, 0.63, 0.31, 1.04)
  &.active
    svg
      pointer-events all
  &.drag
    svg
      cursor move
  &--zoomed
    svg
      .space-name
        display initial !important
        transition all 100ms ease
        user-select none
  & >>> svg
    overflow visible !important
    margin auto
    transition transform 0.2s ease
    @media screen and (max-width: 500px)
      transition transform 0.1s linear
    .space
      transition fill 0.2s ease
      fill $silver
      stroke $dark
      stroke-width 0.2
      &.selected
        opacity 1
        fill white !important
    .wall
      pointer-events none
      fill #333
      stroke-width 0
    .door
      fill $silver
      stroke black
      stroke-width 0.5px
    .window
      fill darken($light, 5%)
      stroke @fill

@media screen and (max-width: 500px)
  .ticket-public
    flex-direction column
    max-width 100%
.copy-link
  margin-left auto

