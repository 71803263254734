@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.ticket-public {
  display: flex;
  margin: auto;
  max-width: 1000px;
  background-color: #fff;
}
.ticket-public .grid-tile__label {
  margin-bottom: 8px;
}
.ticket-public .ticket-main {
  display: flex;
  flex: 2;
  flex-direction: column;
  border-right: 1px solid #f4f4f4;
}
.ticket-public .ticket-main .ticket-title-container {
  padding: 16px;
}
.ticket-public .ticket-main .ticket-title-container .ticket-title {
  font-size: 1.6rem;
}
.ticket-public .ticket-main .status-container {
  padding: 16px;
}
.ticket-public .ticket-main .ticket-infos {
  flex-wrap: wrap;
}
.ticket-public .ticket-main .message-container {
  flex-direction: column;
  padding: 16px;
}
.ticket-public .ticket-main .message-container .form-textarea {
  width: 100%;
  outline: none;
  border: none;
  font-size: 1.3rem;
  resize: none;
}
.ticket-public .ticket-main .comments-container {
  padding: 16px;
}
.ticket-public .ticket-main .comments-container .comment-list {
  width: 100%;
}
.ticket-public .side-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
.ticket-public .side-container .map-container {
  padding: 16px;
  width: 100%;
  height: 355px;
}
.ticket-public .side-container .form-value {
  padding: 16px;
  padding-top: 0;
}
.documents-container {
  flex-direction: column;
  padding: 16px;
  text-align: center;
}
.documents-container .grid-tile__label {
  text-align: left;
}
.documents-container .files-list {
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.documents-container .files-list .file-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  border: 1px solid #e9e8e5;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  color: #827f72;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 0.9rem;
  transition: box-shadow 0.3s ease;
}
.documents-container .files-list .file-item--download {
  cursor: pointer;
}
.documents-container .files-list .file-item--download:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.documents-container .input-files {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}
.documents-container .input-files + label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 100ms ease;
}
.documents-container .input-files + label .picto {
  margin-bottom: 8px;
}
.documents-container .input-files + label:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.documents-container .input-files + label:focus {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.documents-container .input-files + label .inputfile + label * {
  pointer-events: none;
}
.floor-container {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: top 1s cubic-bezier(0.02, 0.63, 0.31, 1.04), left 1s cubic-bezier(0.02, 0.63, 0.31, 1.04), z-index 0.2s cubic-bezier(0.02, 0.63, 0.31, 1.04);
}
.floor-container.active svg {
  pointer-events: all;
}
.floor-container.drag svg {
  cursor: move;
}
.floor-container--zoomed svg .space-name {
  display: initial !important;
  transition: all 100ms ease;
  user-select: none;
}
.floor-container >>> svg {
  overflow: visible !important;
  margin: auto;
  transition: transform 0.2s ease;
}
@media screen and (max-width: 500px) {
  .floor-container >>> svg {
    transition: transform 0.1s linear;
  }
}
.floor-container >>> svg .space {
  transition: fill 0.2s ease;
  fill: #ddd9d3;
  stroke: #464749;
  stroke-width: 0.2;
}
.floor-container >>> svg .space.selected {
  opacity: 1;
  fill: #fff !important;
}
.floor-container >>> svg .wall {
  pointer-events: none;
  fill: #333;
  stroke-width: 0;
}
.floor-container >>> svg .door {
  fill: #ddd9d3;
  stroke: #000;
  stroke-width: 0.5px;
}
.floor-container >>> svg .window {
  fill: #e9e5e1;
  stroke: #e9e5e1;
}
@media screen and (max-width: 500px) {
  .ticket-public {
    flex-direction: column;
    max-width: 100%;
  }
}
.copy-link {
  margin-left: auto;
}
/*# sourceMappingURL=src/app/pages/ticket/public.css.map */