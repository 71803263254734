




































































































.full-screen
  position absolute
  width 100%
  height 100%
  z-index 1
  top 50%
  left 50%
  transform translate(-50%, -50%)
  overflow-y hidden
  cursor move
  transition none
